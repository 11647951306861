<div class="container">
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="form-inputs">
      <!-- Invoice Number Input -->
      <div class="form-group">
        <mat-form-field>
          <mat-label for="invoiceNumber">Invoice Number</mat-label>
          <input matInput id="invoiceNumber" formControlName="invoiceNumber" type="text" />
        </mat-form-field>
      </div>

      <!-- Customer Name Input -->
      <div class="form-group">
        <mat-form-field>
          <mat-label for="customerName">Customer Name</mat-label>
          <input matInput id="customerName" formControlName="customerName" type="text" />
        </mat-form-field>
      </div>

      <!-- Location Name Input -->
      <div class="form-group">
        <mat-form-field>
          <mat-label for="locationName">Location Name</mat-label>
          <input matInput id="locationName" formControlName="locationName" type="text" />
        </mat-form-field>
      </div>

      <!-- Vendor Input -->
      <div class="form-group">
        <mat-form-field>
          <mat-label for="vendor">Vendor</mat-label>
          <input matInput id="vendor" formControlName="vendor" type="text" />
        </mat-form-field>
      </div>

      <!-- Pricing Agreement Input -->
      <div class="form-group">
        <mat-form-field>
          <mat-label for="pricingAgreementName">Pricing Agreement Name</mat-label>
          <input 
            matInput
            id="pricingAgreementName"
            formControlName="pricingAgreementName"
            type="text" />
        </mat-form-field>
      </div>

      <div class="form-inputs">
        <!-- Location State Dropdown -->
        <div class="form-group">
          <mat-form-field>
            <mat-label>Location State</mat-label>
            <mat-select formControlName="locationState" multiple>
              @for (state of states; track state.StateID) {
                <mat-option [value]="state.StateID">{{state.StateName}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Audit Status Dropdown -->
        <div class="form-group">
          <mat-form-field>
            <mat-label>Audit Status</mat-label>
            <mat-select formControlName="auditStatus" multiple>
              @for (status of auditStatuses; track status.AuditStatusID) {
                <mat-option [value]="status.AuditStatusID">{{status.AuditStatusName}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Product Category Dropdown -->
        <div class="form-group">
          <mat-form-field>
            <mat-label>Product Category</mat-label>
            <mat-select formControlName="productCategory" multiple>
              @for (category of productCategories; track category.ProductCategoryID) {
                <mat-option [value]="category.ProductCategoryID">{{category.ProductCategoryName}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input formGroupName="invoiceDateRange" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          
            @if (searchForm.controls["invoiceDateRange"].get('start')?.hasError('matStartDateInvalid')) {
              <mat-error>Invalid start date</mat-error>
            }
            @if (searchForm.controls["invoiceDateRange"].get('end')?.hasError('matEndDateInvalid')) {
              <mat-error>Invalid end date</mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="mt-10 flex gap-5">
      <button
        type="submit"
        [disabled]="searchForm.invalid">
        Search
      </button>
      <button
        type="button"
        [disabled]="searchForm.invalid"
        (click)="onExport()"
        >
        Export
      </button>
    </div>
  </form>

  <div style="margin-top: 10px">
    <!-- Radio Buttons for Column Group Selection -->
    <label>
      <input
        type="radio"
        name="columnGroup"
        value="Tax"
        [(ngModel)]="selectedGroup"
        (change)="updateColumns()" />
      Tax Auditing View
    </label>
    <label>
      <input
        type="radio"
        name="columnGroup"
        value="Price"
        [(ngModel)]="selectedGroup"
        (change)="updateColumns()" />
      Price Auditing View
    </label>
  </div>

  <!-- ag-Grid Component for Displaying Data -->
  <ag-grid-angular
    class="ag-theme-quartz"
    [autoSizeStrategy]="autoSizeStrategy"
    [rowSelection]="rowSelection"
    [rowData]="rowData"
    [columnDefs]="colDefs"
    [stopEditingWhenCellsLoseFocus]="true"
    style="height: 50vh; margin-top: 10px"
    (cellValueChanged)="onCellValueChanged($event)"
    (selectionChanged)="onSelectionChanged($event)"
    (gridReady)="onGridReady($event)"
    #grid />

  <br />

  <!-- Save Button -->
  <button [disabled]="updatedRowIds.length === 0" (click)="saveClicked()">
    Save
  </button>

  <button style="margin-left: 15px;" [disabled]="!canBulkSave" (click)="confirmBulkSaveClicked()">
    Bulk Save
  </button>
  <br />
</div>
